<template>
    <div class="generate-certificate">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
            <el-breadcrumb-item>证书模板</el-breadcrumb-item>
            <el-breadcrumb-item>未获得证书</el-breadcrumb-item>
            <el-breadcrumb-item>添加证书模板</el-breadcrumb-item>
        </el-breadcrumb>
        <el-scrollbar class="certificate-content" :native="false">
            <div class="certificate-content-item">
                <el-form :model="certificateForm" ref="certificateForm" :rules="rules" label-position="right" label-width="240px" :hide-required-asterisk="true">
                    <div class="certificate-info">
                        <el-form-item label="证书名称"  prop="certificate_name" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.certificate_name" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入证书名称"></el-input>
                        </el-form-item>
                        <el-form-item label="证书名称（英文）"  prop="english_name" class="certificate-form-right-en" >
                            <el-input v-model="certificateForm.english_name" autocomplete="off" style="width: 500px"
                                      placeholder="请输入证书名称（英文）"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="评价机构名称"  prop="evaluate_name" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.evaluate_name" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入评价机构名称"></el-input>
                        </el-form-item>
                        <el-form-item label="评价机构名称（英文）"  prop="evaluate_english_name" class="certificate-form-right-en">
                            <el-input v-model="certificateForm.evaluate_english_name" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入评价机构名称（英文）"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="证书信息查询网址"  prop="information" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.information" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入证书信息查询网址"></el-input>
                        </el-form-item>
                        <el-form-item label="机构信息查询网址"  prop="organization" class="">
                            <el-input v-model="certificateForm.organization" autocomplete="off"  style="width: 500px"
                                      placeholder="请输入机构信息查询网址"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="职业名称"  prop="occupation_name" class="certificate-form-left" >
                            <el-input class="certificate-input" v-model="certificateForm.occupation_name" autocomplete="off" style="width: 500px"
                                      placeholder="请输入职业名称"></el-input>
                        </el-form-item>
                        <el-form-item label="工种/职业方向"  prop="job" class="" style="width: 500px">
                            <el-input class="certificate-input" v-model="certificateForm.job" autocomplete="off" style="width: 500px"
                                      placeholder="请输入工种/职业方向"></el-input>
                        </el-form-item>
                    </div>
                    <div class="certificate-info">
                        <el-form-item label="职业技能等级"  prop="skill_level" class="certificate-form-left">
                            <el-input class="certificate-input" v-model="certificateForm.skill_level " autocomplete="off" style="width: 500px"
                                      placeholder="请输入职业技能等级"></el-input>
                        </el-form-item>
                        <el-form-item label="分支机构名称"  prop="f_organization">
                            <el-input class="certificate-input" v-model="certificateForm.f_organization " autocomplete="off" style="width: 500px"
                                      placeholder="请输入分支机构名称"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="证书编码"  prop="certificate_num">-->
<!--                            <div style="display: flex;justify-content: space-between">-->
<!--                                <el-input-number v-model="certificateForm.certificate_num" :precision="0" :controls="false" placeholder="请输入证件编码" style="width: 390px;margin-right: 10px"></el-input-number>-->
<!--                                <el-button type="primary"  @click="randomGeneration()">随机生成</el-button>-->
<!--                            </div>-->
<!--                        </el-form-item>-->
                    </div>
                    <div class="certificate-info  certificate-form-left" >
                        <el-form-item label="发证日期"  prop="dateTimeStamp" >
                            <el-date-picker
                                    style="width: 500px"
                                    v-model="certificateForm.dateTimeStamp"
                                    type="date"
                                    value-format="timestamp"
                                    placeholder="选择日期"
                                    @change="dateTimeChange"
                                    :picker-options="pickerOptions0">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="certificate-item">
                        <ViewCertificateModule :obj="certificateForm"></ViewCertificateModule>
                    </div>
                </el-form>
                <div class="btn-footer">
                    <el-button   @click="toBack()">返回</el-button>
                    <el-button  type="primary" @click="submitCertificate()">保存</el-button>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {certificateCertificateTemplate,certificateShowTemplate} from '@/utils/apis'
    import ViewCertificateModule from '../../../components/teacher/certificateContent/ViewCertificateModule.vue'
    export default {
        name: "CertificateModel",
        components: {
            ViewCertificateModule
        },
        data(){
            return{
                //限制当天前的时间不可点击
                pickerOptions0: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                certificateForm:{
                    id: Number(this.$route.query.id) || null,
                    type_name:'',
                    certificate_name:'',
                    english_name:'',
                    evaluate_name:'',//评价机构
                    evaluate_english_name:'',
                    information11:'',//证书信息查询网址
                    organization :'',//机构信息查询网址
                    account :'',//姓名
                    id_type:'',//身份证
                    id_name:'',//证件号码名
                    occupation_name:'',//职业名称
                    job:'',//工种/职业方向
                    skill_level:'',//职业技能等级
                    certificate_num:void 0,//证件编码
                    f_organization:'',//分支机构名称
                    date_time:'',//发证日期
                    date_etime:'',//发证日期英文
                    dateTimeStamp: '',
                    er_code: '',
                },
                certificateList:[],
                idTypeList:[],
                rules: {
                    model: [
                        {required: true, message: '请选择证书模板', trigger: 'change'},
                    ],
                    certificate_name: [
                        {required: true, message: '请输入证书名称', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
                    ],
                    english_name: [
                        {required: true, message: '请输入证书英文名称', trigger: ['blur', 'change']},
                        {min: 2, max: 46, message: '长度在2 到 46 个字符', trigger: ['blur', 'change']}
                    ],
                    evaluate_name: [
                        {required: true, message: '请输入评价机构', trigger: 'blur'},
                        {min: 2, max:10, message: '长度在 2 到 10个字符', trigger: ['blur', 'change']}
                    ],
                    evaluate_english_name: [
                        {required: true, message: '请输入评价机构（英文）', trigger: 'blur'},
                        {min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur'}
                    ],
                    information: [
                        {required: true, message: '请输入证书信息查询网址', trigger: 'blur'},
                        {min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur'}
                    ],
                    organization: [
                        {required: true, message: '请输入机构信息查询网址', trigger: 'blur'},
                        {min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur'}
                    ],
                    occupation_name: [
                        {required: true, message: '请输入职业名称', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
                    ],
                    job: [
                        {required: true, message: '请输入工种/职业方向', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
                    ],
                    skill_level: [
                        {required: true, message: '请输入职业技能等级', trigger: 'blur'},
                        {min: 2, max: 3, message: '长度在 2 到 3 个字符', trigger: 'blur'}
                    ],
                    certificate_num: [
                        {required: true, message: '请输入证件编码', trigger: ['blur', 'change']},
                    ],
                    // date_time: [
                    //     { required: true, message: '请选择证书日期', trigger:  ['blur', 'change']}
                    // ],
                    dateTimeStamp: [
                        { required: true, message: '请选择证书日期', trigger:  ['blur', 'change']}
                    ],

                },
            }
        },
        mounted() {
            if (this.certificateForm.id) {
                this.getCertificateDetail()
            }
        },
        methods:{
            // 获取证书详情
            getCertificateDetail() {
                let params = {
                    id: this.certificateForm.id
                }
                certificateShowTemplate(params).then((res) => {
                    this.certificateForm = {
                        id: Number(this.$route.query.id) || null,
                        certificate_name: res.data.certificate_name,
                        english_name: res.data.certificate_ename,
                        evaluate_name: res.data.appraise_name,
                        evaluate_english_name: res.data.appraise_ename,
                        information: res.data.certificate_info_site,
                        organization: res.data.appraise_info_site,
                        occupation_name: res.data.work_name,
                        job: res.data.work_type,
                        skill_level: res.data.work_class,
                        certificate_num: res.data.certificate_num,
                        f_organization: res.data.branch_organiza_name,
                        dateTimeStamp: res.data.certificate_time * 1000,
                    }
                    this.timeFormat(res.data.certificate_time * 1000)
                })
            },
            //保存证书
            submitCertificate(){
                let params = {
                    certificate_name: this.certificateForm.certificate_name,
                    certificate_ename: this.certificateForm.english_name,
                    appraise_name: this.certificateForm.evaluate_name,
                    appraise_ename: this.certificateForm.evaluate_english_name,
                    certificate_info_site: this.certificateForm.information,
                    appraise_info_site: this.certificateForm.organization ,
                    work_name: this.certificateForm.occupation_name,
                    work_type: this.certificateForm.job,
                    work_class: this.certificateForm.skill_level,
                    certificate_num: this.certificateForm.certificate_num,
                    branch_organiza_name: this.certificateForm.f_organization,
                    certificate_time: parseInt(this.certificateForm.dateTimeStamp / 1000),
                }
                if (this.certificateForm.id) {
                    params.id = this.certificateForm.id;
                }
                certificateCertificateTemplate(params).then((res) => {
                    this.$message.success(res.msg);
                    this.$router.push({
                        path: '/evaluationCenter/certificateManagement/model',
                    })
                }).catch((err) => {
                    console.log(err);
                });

            },
            toBack(){
                this.$router.push({
                    path: '/evaluationCenter/certificateManagement/model',
                })
            },
            //随机生成
            randomGeneration(){
                this.certificateForm.certificate_num = '';
                for (let i = 0; i < 12; i++) {
                    this.certificateForm.certificate_num += Math.floor(Math.random() * 10);
                }
            },
            //转时间戳
            dateTimeChange(val) {
               this.timeFormat(val)
            },
            timeFormat(dateTimeStamp){
                // 日期选择器确认
                let time = new Date(dateTimeStamp)
                let y = time.getFullYear();
                let m = time.getMonth()>8?time.getMonth()+1:'0'+(time.getMonth()+1);
                let d = time.getDate()>9?time.getDate():'0'+time.getDate();
                this.certificateForm.date_time = y+'年'+m+'月'+d+'日';
                // this.certificateForm.dateTimeStamp = val;
                let ye = time.toDateString().split(" ")[3];
                let me = time.toDateString().split(" ")[1];
                let de = parseInt(time.toDateString().split(" ")[2])
                this.certificateForm.date_etime = ''+ me +' '+ de + ',' + ye;
            }
        }
    }
</script>

<style scoped lang="scss">
    .generate-certificate{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .certificate-content {
            height: 100%;
            background: #fff;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
            .certificate-content-item{
                padding: 0 60px;
                margin-top: 20px;
                .certificate-info {
                    display: flex;
                    justify-content: flex-start;
                    ::v-deep .el-input__inner {
                        height: 40px;
                        line-height: 40px;
                    }
                    ::v-deep .el-form-item__label {
                        color: #333;
                        font-size: 18px;
                    }

                    .certificate-form-left {
                        margin-right: 90px;
                    }
                    .certificate-form-cod {
                        display: flex;

                        ::v-deep .el-form-item__label {
                            width: 330px !important;
                        }
                        ::v-deep .el-form-item__content{
                            margin-left:0 !important;
                        }
                    }
                    ::v-deep .el-input-number {
                        .el-input__inner {
                            text-align: left;
                        }
                    }
                    /*.certificate-form-right-en{*/
                    /*    ::v-deep .el-form-item__label{*/
                    /*        width: 135px!important;*/
                    /*    }*/
                    /*    ::v-deep .el-form-item__content{*/
                    /*        margin-left: 135px!important;*/
                    /*    }*/
                    /*}*/
                }
            }
            .btn-footer{
                text-align: center;
                margin: 20px 0;
            }
        }
    }

</style>
